<template>
  <v-container
    fluid
    pa-0
    fill-height
    grid-list-xs
  >
    <v-layout
      row
      wrap
      justify-center
    >
      <template
        v-if="tickets.count == 0 && !dialog"
      >
        <v-container
          fluid
          pa-0
          fill-height
        >
          <v-layout
            align-center
            justify-center
            row

            wrap
            fill-height
          >
            <v-flex
              xs12

              text-center
            >
              <v-icon x-large>
                mdi-alert
              </v-icon>
              <v-flex
                xs12
                px-5
                mx-5
                text-center
              >
                No tickets found
              </v-flex>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template
        v-else
      >
        <v-flex
          v-for="(ticket, index) in tickets.results"
          :key="index"
          xs12
          px-2
          py-1
        >
          <v-card
            v-ripple="{class:`blue-grey--text text--lighten-3`}"
            tile
            hover
            ripple
            py-4
            color="grey lighten-5"
            @click="$router.push({name: 'viewticket', params: {id: ticket.id, company: company, status: ticket.status, date: ticket.created_at }})"
          >
            <v-card-title

              class="blue-grey lighten-4 pa-1"
            >
              <v-layout
                row
                align-center
                fill-height
              >
                <v-flex
                  class="bg-success"
                >
                  <h4 class="font-weight-bold blue-grey--text text--darken-2 pl-1 pa-0 ma-0">
                    Ticket #{{ ticket.id }}
                  </h4>
                </v-flex>
                <v-flex
                  text-right
                  pr-1
                  align-center
                >
                  <span class="body-2 blue-grey--text text--darken-2">{{ formatDate(ticket.created_at) }}</span>
                </v-flex>
                <v-flex
                  shrink
                  text-xs-right
                >
                  <v-chip
                    :class="colorStatus(ticket.status)"
                    small
                  >
                    <span
                      style="width:50px; text-align:center"
                      class="white--text font-weight-medium text-capitalize"
                    >{{ ticket.status }}
                    </span>
                  </v-chip>
                </v-flex>
              </v-layout>
            </v-card-title>
            <v-container
              pb-3
              pt-1
              class="bg-success"
            >
              <v-layout>
                <v-flex v-if="ticket.via.source.from.name !== null || ticket.via.source.from.address !== null">
                  <span class="font-weight-medium body-2">From:</span> <span class="body-1"> {{ ticket.via.source.from.name }} </span> <span
                    v-if="ticket.via.source.from.address !==null"
                    class="font-weight-light font-italic caption"
                  >({{ ticket.via.source.from.address }})</span>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex v-if="ticket.subject !== null">
                  <h5

                    class="font-weight-medium pt-0 mt-0 mb-1 body-2"
                  >
                    {{ ticket.subject }}
                  </h5>
                </v-flex>
              </v-layout>
              <v-layout
                row
              >
                <v-flex
                  h-200
                  text-truncate
                  class="body-1"
                >
                  {{ ticket.description }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
    <v-container
      v-if="dialog"
      pa-0
      fill-height
    >
      <v-layout
        align-center
        justify-center
        row
        fill-height
      >
        <v-flex
          shrink
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue-grey darken-2"
            indeterminate
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      dialog: true,
      tickets: {
        count: 0
      }
    }
  },
  computed: {
    company () {
      return this.$route.query.company
    },
    data () {
      return this.$store.getters.storedData
    }
  },
  created () {
    if (!this.$store.state.temp) {
      this.$http.get(this.$api_url + 'zendesk/company-tickets/' + this.company, {
        headers: {
          'OAuth-Token': this.$store.getters.token
        }
      })
        .then(response => {
          this.dialog = false
          this.tickets = response.data
          this.$store.dispatch('storeData', response.data)
        })
    } else {
      this.dialog = false
      this.tickets = this.$store.state.temp
    }
  },
  methods: {
    humanizeDate (date) {
      return this.$moment(date).fromNow()
    },
    formatDate (date) {
      return this.$moment(date).format('MMM Do, YYYY')
    },
    colorStatus (status) {
      switch (status) {
        case 'closed':
          return 'blue-grey darken-2'
        case 'solved':
          return 'blue-grey darken-2'
        case 'open':
          return 'red '
        case 'new':
          return 'yellow darken-3'
        case 'pending':
          return 'light-blue darken-4'
      }
    }
  }
}
</script>
<style>
    #core-view{
        height: 100%;
    }
 </style>

